import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    .admin-panel-text-box {
        width: 32%;
        margin: 1em 0;

        &.size-fullWidth {
            width: 100%;
        }
    }

    @media (min-width: ${variables.tabletL}) {
        .admin-panel-text-box {
                &:not(.size-fullWidth) {
                    width: 32%;
                }
            }
        }
    }

    @media (max-width: ${variables.tabletS}) {
        .admin-panel-text-box {
                &:not(.size-fullWidth) {
                    width: 48%;
                }
            }
        }
    }

    @media (max-width: ${variables.mobileM}) {
        .admin-panel-text-box {
                &:not(.size-fullWidth) {
                    width: 100%;
                }
            }
        }
    }
`;
