import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getFullName as getUserFullName } from 'Utils/user';
import { getFormattedDate } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import TextBox from 'Components/layout/panel/TextBox';

export default class AdeptAdeptTestsEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: this.props.data || {},  //eslint-disable-line react/destructuring-assignment
    };

    render() {
        const { data, location, history } = this.props;
        const { formState } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="adept-adept-test-editor"
                styles={require('./styles')}
            >
                <TextBox 
                    title="Nazwa"
                    subtitle={data.name}
                    image={{
                        isVisible: true,
                        url: require('Theme/images/icons/dumbbell.svg'),
                    }}
                    styleVersion={2}
                />
                <TextBox 
                    title="Data"
                    subtitle={getFormattedDate(data.testDate) || 'Brak'}
                    image={{
                        isVisible: true,
                        url: require('Theme/images/icons/calendar.svg'),
                    }}
                    styleVersion={2}
                />
                <TextBox 
                    title="Trener"
                    subtitle={getUserFullName(data.trainer, 'nameSurname').label}
                    image={{
                        isVisible: true,
                        url: require('Theme/images/icons/user.svg'),
                    }}
                    styleVersion={2}
                />
                <TextBox 
                    title="Opis"
                    description={data.description}
                    styleVersion={2}
                    size='fullWidth'
                />
            </StyledComponent>
        );
    }
}